/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react';
import { PageContextProvider } from './src/context/PageContext';

export const wrapPageElement = ({ props, element }) => {
  return (
    <PageContextProvider value={{ pageContext: props.pageContext }}>{element}</PageContextProvider>
  );
};
