/* eslint-disable react/forbid-prop-types */
import React, { useContext, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const PageContext = createContext();

export function PageContextProvider({ value, children }) {
  const lang = value.pageContext.lang ? value.pageContext.lang : `hr`;

  const contextValue = useMemo(() => ({ lang, ...value.pageContext }), [lang, value.pageContext]);

  return <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>;
}

const usePageContext = () => useContext(PageContext);

export default usePageContext;

PageContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
