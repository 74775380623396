import { extendTheme } from '@chakra-ui/react';

const Modal = {
  // The styles all button have in common
  baseStyle: props => ({
    dialogContainer: {
      paddingLeft: { base: '2', xs: '4', md: 'unset' },
      paddingRight: { base: '2', xs: '4', md: 'unset' },
    },
    dialog: {
      rounded: '0',
      backgroundColor: '#F5F5F5',
    },
    header: {
      px: { base: '4', xs: '6', md: '10' },
      pt: { base: '6', md: '8' },
      fontSize: '2xl',
      fontWeight: 'bold',
      color: 'gray.dark',
      borderBottom: '1px solid',
      borderColor: 'gray.lighter',
      paddingBottom: '0',
      marginBottom: '4',
    },
    body: {
      px: { base: '4', xs: '6', md: '10' },
      color: 'gray.dark',
      marginBottom: '4',
    },
    footer: {
      px: { base: '4', xs: '6', md: '10' },
      pt: '2',
      color: 'gray.dark',
      pb: { base: '2', md: '6' },
      marginBottom: '4',
    },
  }),
};

const Heading = {
  // The styles all button have in common
  baseStyle: {
    fontWeight: 800,
  },
};

const Text = {
  // The styles all texts have in common
  baseStyle: {
    fontSize: '18px',
    color: 'gray.dark',
  },
};

const theme = extendTheme({
  components: {
    Heading,
    Text,
    Modal,
  },
  breakpoints: {
    xs: '380px',
    sm: '480px',
    md: '768px',
    lg: '960px',
    xl: '1200px',
    '2xl': '1536px',
    '3xl': '1800px',
  },
  // Default color mode
  initialColorMode: 'light',
  useSystemColorMode: false,
  // Customize border radius
  radii: {
    brandRadius: {
      card: '15px',
      image: '15px',
      button: '10px',
      avatar: '9999px',
      badge: '0.375rem',
    },
  },
  // Customize default fonts
  fonts: {
    body: 'Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    heading:
      'Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  // Customize brand colors
  colors: {
    brand: {
      main: '#0075FF',
      underline: '#44A1FF',
      light: '#3399FF',
      lighter: '#78BCFF',
      lightest: '#72B3FF',
      lightestOg: '#BDDEFF',
      darker: '#0163D6',
      dark: '#086CE1',
    },
    gray: {
      lighter: '#EEEEEE',
      light: '#CDCDCD',
      dark: '#141414',
      main: '#505050',
      medium: '#828282',
    },
    neutral: {
      light: '#858585',
    },
  },
  styles: {
    global: props => ({
      // Style content field in postTemplate.js & pageTemplate.js
      h1: {
        fontSize: '4xl',
        fontWeight: 'bold',
      },
      h2: {
        fontSize: '3xl',
        fontWeight: 'bold',
      },
      p: {
        fontSize: 'lg',
        color: 'gray.dark',
      },
      ul: {
        fontSize: 'lg',
        color: 'gray.dark',
      },
      a: {
        textDecoration: 'underline',
        textDecorationColor: 'brand.main',
      },
      button: {
        textDecoration: 'none',
      },
      '.wysiwyg': {
        '.blocks-gallery-item__caption': {
          borderRadius: 'xl',
        },
        '.gatsby-image-wrapper': {
          marginBottom: '6',
        },
        '.blocks-gallery-grid': {
          ml: '0',
          my: '2',
          '.gatsby-image-wrapper': {
            marginBottom: 0,
          },
        },
        img: {
          borderRadius: 'xl',
        },
        a: {
          color: 'gray.dark',
          textDecoration: 'underline',
        },
      },
      '.mailchimpMessage': {
        a: {
          textDecoration: 'underline',
          color: 'secondary.600',
        },
      },
      // image radius fix for iOS
      '.chakra-image': {
        img: {
          borderRadius: 'brandRadius.image',
          borderBottomRadius: '0',
        },
      },
    }),
  },
});

export default extendTheme(theme);
